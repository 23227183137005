import { gql } from "@apollo/client";
import { USER_FRAGEMENT } from "./userFragment";

export const PARAMS_FRAGMENT = gql`
  fragment ParamsFragment on Job {
    params {
      module
      field
      salary
      availability
      softSkills
      englishLevel
      moduleCertified
      moduleCertificateValue
    }
  }
`;
export const CANDITATES_FRAGMENT = gql`
  ${USER_FRAGEMENT}
  fragment CandidatesFragment on Job {
    candidates {
      score {
        key
        value
        message
      }
      total
      candidate {
        ...UserFragemnt
      }
    }
  }
`;
export const MODULE_FRAGMENT = gql`
  fragment ModuleFragment on Job {
    modules {
      module
      experience
      certified
      certificate
    }
  }
`;

export const SALARY_FRAGMENT = gql`
  fragment SalaryFragment on Job {
    salary {
      payroll
      receipt
    }
  }
`;
export const SKILLS_FRAGMENT = gql`
  fragment SkillsFragment on Job {
    englishLevel {
      reading
      writing
      speaking
    }
    softSkills {
      trabajoEquipo
      liderazgo
      comunicacionEfectiva
      escuchaActiva
      adaptabilidad
      creatividad
      espirituServicio
      comunicacion
      empatia
      proactividad
      gestionConflicto
      gestionCambio
    }
  }
`;

export const AVAILABILITY_FRAGMENT = gql`
  fragment AvailabilityFragment on Job {
    availability {
      inmediate
      date
    }
  }
`;

export const JOB_FRAGMENT = gql`
  fragment JobFragment on Job {
    _id
    title
    position
    description
    content
    fields
    status
    updatedAt
    createdAt
    jobCategory
    consultantJob
    salaryRange
    type
    jobCommute
    address {
      country
      city
    }
  }
`;
