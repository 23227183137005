import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";
import { IS_LOGGED_IN } from "./queries/local/isLoggedIn";
import { GET_CURRENT_USER } from "./queries/users/getCurrentUser";

const getToken = (): string | undefined => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem("token");
    return token ? `Bearer ${token}` : undefined;
  }
  return undefined;
};

const getLink = (): ApolloLink => {
  // Base link
  const httpLink = createHttpLink({
    uri: "https://netlify-api.netlify.app/graphql",
    credentials: "same-origin",
    headers: {
      authorization: getToken(),
    },
    fetch,
  });
  return httpLink;
};

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: getLink(),
  cache: cache,
  connectToDevTools: true,
});

const hasToken = (): boolean => {
  if (typeof window !== "undefined") {
    return !!localStorage.getItem("token");
  }
  return false;
};

const setInitialState = async (): Promise<void> => {
  // Get current user if token is present
  if (hasToken()) {
    const { data } = await client.query({
      query: GET_CURRENT_USER,
    });
    // Check if token is expired or invalid for some reason
    if (!data.getCurrentUser) localStorage.removeItem("token");
  }
  // Set initial logged in state
  await cache.writeQuery({
    query: IS_LOGGED_IN,
    data: { isLoggedIn: hasToken() },
  });
};
client.onResetStore(async () => {
  client.setLink(getLink());
  await setInitialState();
});
