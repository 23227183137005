import React, { useState, createContext, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LinkType } from "../types/link";

type AppContextType = {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  locale: string;
  setLocale: (value: "es-PE" | "en-US") => void;
  links: LinkType[];
  setSelectId: (value: string) => void;
  setCategoryId: (value: string) => void;
  setServiceId: (value: string) => void;
  selectId: null | string;
  categoryId: null | string;
  serviceId: null | string;
  formRef: any;
  secureUrl: string;
  setSecureUrl: (value: string) => void;
};

const defaultState = {
  menuOpen: false,
  setMenuOpen: () => {},
  locale: "es-PE",
  setLocale: () => {},
  links: [],
  setSelectId: () => {},
  selectId: null,
  serviceId: null,
  categoryId: null,
  formRef: null,
  secureUrl: null,
  setCategoryId: () => {},
  setServiceId: () => {},
  setSecureUrl: () => {},
};

export const AppContext = createContext<AppContextType>(defaultState);

export function AppProvider({ children }) {
  const data = useStaticQuery(graphql`
    query Myquery {
      allContentfulServiceCategory(sort: { fields: order, order: ASC }) {
        nodes {
          title
          slug
          id
          node_locale
          contentfulparent {
            title
          }
          service_category {
            title
            slug
            id
            services {
              id
            }
          }
          services {
            title
            slug
            id
          }
        }
      }

      allContentfulPage(sort: { order: ASC, fields: order }) {
        nodes {
          title
          slug
          id
          order
          node_locale
          page {
            title
            slug
            id
            node_locale
          }
          contentfulparent {
            title
          }
        }
      }
    }
  `);
  const linksES = data.allContentfulPage.nodes
    .filter((link) => link.node_locale === "es-PE")
    .filter((link) => !link.slug.includes("https"));

  const linksEN = data.allContentfulPage.nodes
    .filter((link) => link.node_locale === "en-US")
    .filter((link) => !link.slug.includes("https"));
  const serviceES = data.allContentfulServiceCategory.nodes.filter(
    (service) => service.node_locale === "es-PE"
  );

  const serviceEN = data.allContentfulServiceCategory.nodes.filter(
    (service) => service.node_locale === "en-US"
  );

  linksES.forEach((link) => {
    if (link.title === "Servicios") {
      link.page = serviceES.filter((link) => link.contentfulparent === null);
    }
  });

  linksEN.forEach((link) => {
    if (link.title === "Services") {
      link.page = serviceEN.filter((link) => link.contentfulparent === null);
    }
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const [locale, setLocale] = useState("es-PE");
  const [selectId, setSelectId] = useState(null);
  const [serviceId, setSerId] = useState(null);
  const [categoryId, setCatId] = useState(null);
  const [secureUrl, setSecureUrl] = useState(null);

  const setCategoryId = (id: string) => {
    setCatId(id);
  };
  const setServiceId = (id: string) => {
    setSerId(id);
  };

  const formRef = useRef(null);
  const links =
    locale === "es-PE"
      ? linksES.filter((link) => link.contentfulparent === null)
      : linksEN.filter((link) => link.contentfulparent === null);
  return (
    <AppContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        links,
        locale,
        setLocale,
        selectId,
        setSelectId,
        setCategoryId,
        setServiceId,
        categoryId,
        serviceId,
        formRef,
        secureUrl,
        setSecureUrl,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
