import React from "react";
import "typeface-mulish";
import { ApolloProvider } from "@apollo/client";

import { AppProvider } from "./src/context/app-context";
import { LocationProvider } from "./src/context/location-context";
import { client } from "./src/apollo/client";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <LocationProvider>
      <AppProvider>{element}</AppProvider>
    </LocationProvider>
  </ApolloProvider>
);
