import { gql } from "@apollo/client";

export const USER_FRAGEMENT = gql`
  fragment UserFragemnt on User {
    _id
    name
    email
    phone
    curriculum
    identification {
      number
      type
    }
    role
    onboarding {
      finished
      link
    }
    isConsultant
    birthdate
    address {
      country
      city
    }
    salary {
      payroll
      receipt
    }
    availability {
      inmediate
      date
    }
    fields
    softSkills {
      trabajoEquipo
      liderazgo
      comunicacionEfectiva
      escuchaActiva
      adaptabilidad
      creatividad
      espirituServicio
      comunicacion
      empatia
      proactividad
      gestionConflicto
      gestionCambio
    }
    englishLevel {
      speaking
      writing
      reading
    }
    modules {
      module
      experience
      certified
      certificate
    }
    experiences {
      _id
      position
      startDate
      endDate
      currentJob
      description
      referral {
        name
        phone
        email
        position
        company
      }
    }
  }
`;
