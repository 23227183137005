const colors = {
  blue: ["#80A6F9", "#2063F4", "#1F3358"],
  dark: ["#767F97", "#5D687D", "#495162", "#333844", "#000000"],
  light: ["#FFFFFF", "#E9EBEF", "#CDD0D9", "#AFB5C3", "#939BAD"],
};
const defaultFontStack = [
  "Mulish",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
];

export default {
  colors: {
    ...colors,
    text: colors.dark[3],
    background: colors.light[0],
    primary: colors.blue[1],
    header: colors.blue[2],
    footer: colors.light[1],
    muted: colors.light[1],
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 24, 32, 48, 56, 64],
  fonts: {
    body: defaultFontStack.join(),
    heading: "Mulish",
    monospace: "Menlo, monospace",
  },
  breakpoints: ["600px", "960px", "1280px", "1920px"],
  fontWeight: { body: 400, heading: 900, bold: 700 },
  lineHeight: { heading: 1.4, body: 1.5 },

  text: {
    default: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
    title: {
      fontSize: [4, null, null, 5, 6],
      color: "header",
      textAlign: "center",
      mb: [4, null, null, null, 5],
    },
    secondaryTitle: {
      fontSize: [4, null, null, 4, 5],
      color: "header",
      textAlign: "center",
      mb: [4, null, null, 5],
    },
  },

  buttons: {
    primary: {
      fontFamily: "heading",
      lineHeight: "body",
      p: [2, 3],
      color: colors.light[0],
      bg: "primary",
      fontSize: [0, 2],
      fontWeight: "heading",
      textTransform: "uppercase",
      borderRadius: ["8px", "24px"],
      cursor: "pointer",
      "&:hover": {
        bg: colors.blue[2],
        color: colors.light[0],
      },
    },
    clientMenu: {
      borderRadius: "0",
      py: 3,
      px: 4,
      width: "100%",
      bg: ["transparent"],
      fontWeight: ["body"],
      textTransform: ["none"],
      color: "text",
      display: "flex",
      cursor: "pointer",
      "&:hover": {
        bg: "muted",
        color: "text",
      },
    },
    header: {
      fontFamily: "heading",
      lineHeight: "body",
      p: [2],
      color: colors.light[0],
      bg: "primary",
      fontSize: [0, 2],
      fontWeight: "heading",
      textTransform: "uppercase",
      borderRadius: ["8px", "24px"],
    },
  },
};
