import React, { useRef } from "react";

const defaultState = {
  serviceRef: null,
  serviceCategoryRef: null,
};

export const LocationContext = React.createContext(defaultState);

export function LocationProvider({ children }) {
  const serviceRef = useRef(null);
  const serviceCategoryRef = useRef(null);
  return (
    <LocationContext.Provider value={{ serviceRef, serviceCategoryRef }}>
      {children}
    </LocationContext.Provider>
  );
}
