import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "../../fragments/jobFragment";
import { USER_FRAGEMENT } from "../../fragments/userFragment";

export const GET_CURRENT_USER = gql`
  ${USER_FRAGEMENT}
  ${JOB_FRAGMENT}
  query {
    getCurrentUser {
      ...UserFragemnt
      applications {
        ...JobFragment
      }
    }
  }
`;
