module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BZ67GJWWZ5"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-praxisnet/gatsby-browser.js'),
      options: {"plugins":[],"spaceID":"l6s0ygcmwhzf","accessToken":"BWDz4ThIEL5LU-sP7olzW4BB6P8QXBnjize943eNOTg","faunaToken":"fnAEBOaUFuACBEIkk0-9o65fjuyHCt45L1K8EBUQ"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Praxisnet","short_name":"Praxisnet","start_url":"/","background_color":"#80A6F9","theme_color":"#2063F4","display":"minimal-ui","icon":"images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"133e13ffbb6757c836fe154977960544"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
